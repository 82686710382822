@page {
    size: auto;
    margin: 0;
}

@media print {
    .no-print, #side-bar {
        display: none !important;
    }

    .expansion-print .MuiCollapse-hidden {
        visibility: visible !important;
    }

    .expansion-print .MuiCollapse-container {
        height: 100% !important;
    }

    #customerly-container {
        display: none !important;
    }
}
